import request from '@/utils/request'
const requestApi = {
  wms_consumable_list: '/wms/consumable/',
  wms_consumable_create: '/wms/consumable/',
  wms_consumable_update: '/wms/consumable/',
  wms_consumable_delete: '/wms/consumable/'
}

export function consumable_list (query) {
  return request({
    url: requestApi.wms_consumable_list,
    method: 'get',
    params: query
  })
}

export function consumable_create (data) {
  return request({
    url: requestApi.wms_consumable_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function consumable_update (data, consumable_id) {
  return request({
    url: requestApi.wms_consumable_update + consumable_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function consumable_delete (consumable_id) {
  return request({
    url: requestApi.wms_consumable_update + consumable_id + '/',
    method: 'delete'
  })
}
